import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationsEn from './locales/translationEn.json'
import translationsRu from './locales/translationRu.json'
import { Locale } from './types/Locale'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Locale.EN,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: translationsEn,
      ru: translationsRu,
    },
  })

i18n.languages = [Locale.EN, Locale.RU]

export default i18n
