interface IProps {
  width?: number
}
const Logo = ({ width = 50 }: IProps) => {
  return (
    <div>
      <img src="/vocabulary.png" width={`${width}px`} />
    </div>
  )
}

export default Logo
