import { makeAutoObservable } from 'mobx'
import { IUserResponse } from 'src/api/types/user'

class UserStore {
  user: IUserResponse | null = null
  constructor() {
    makeAutoObservable(this)
  }

  setUser(user: IUserResponse | null) {
    this.user = user
  }
}

export default new UserStore()
