import { ISuccess } from 'src/api/types/success'

import { client } from '../axios'
import { ApiRoutes } from '../routes'
import { IUserResponse } from '../types/user'

interface IPostUserBody {
  email: string
  password: string
  name: string
}

interface IPatchStartRestoration {
  email: string
}

interface IPatchFinishRestoration {
  password: string
  restorationCode: string | undefined
}

export const postUser = async (body: IPostUserBody): Promise<ISuccess> => {
  return (await client.post(ApiRoutes.postUser, body)).data
}

export const patchStartRestoration = async (
  body: IPatchStartRestoration,
): Promise<ISuccess> => {
  return (await client.patch(ApiRoutes.patchStartRestoration, body)).data
}

export const patchFinishRestoration = async (
  body: IPatchFinishRestoration,
): Promise<ISuccess> => {
  return (await client.patch(ApiRoutes.patchFinishRestoration, body)).data
}

interface IWhoAmIResponse {
  user: IUserResponse
}

export const getWhoAmI = async (): Promise<IWhoAmIResponse> => {
  return (await client.get(ApiRoutes.getWhoAmI)).data
}
