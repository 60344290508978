import { CircularProgress, Container } from '@mui/material'
import React from 'react'

const loadingSize = {
  small: {
    size: 30,
    minHeight: '0px',
  },
  medium: {
    size: 50,
    minHeight: '50px',
  },
  large: {
    size: 70,
    minHeight: '100px',
  },
}

interface IProps {
  size?: 'small' | 'medium' | 'large'
}

const LoadingContainer = ({ size = 'large' }: IProps) => (
  <Container
    style={{
      height: '100%',
      minHeight: loadingSize[size].minHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={loadingSize[size].size} />
  </Container>
)

export default LoadingContainer
